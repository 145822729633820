<style lang="scss">
.badge {
  cursor: pointer;
}
.check-in-out-box {
  padding: 25px !important;
  span {
    padding: 10px;
    background: white;
    -webkit-box-shadow: 0 10px 6px -6px #efefef;
    -moz-box-shadow: 0 10px 6px -6px #efefef;
    box-shadow: 0 10px 6px -6px #efefef;
  }
}
.holiday {
  cursor: pointer;
}
</style>
<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-5">
        <div class="col-lg-4">
          <input
            id="inline-form-input-name"
            type="date"
            v-model="date"
            placeholder="Employee ID"
            class="mb-3 mr-sm-3 mb-sm-0 form-control"
            @change="serachAttendance(true)"
          />
        </div>

        <div class="col-lg-4">
          <select
            class="mb-3 mr-sm-3 mb-sm-0 custom-select"
            v-model="month"
            @change="serachAttendance(false)"
          >
            <option value="">--Select Month--</option>
            <option
              v-for="(month, index) in getMonths"
              :key="index"
              :value="index+1"
            >
              {{ month }}
            </option>
          </select>
        </div>
        <div class="col-lg-4">
          <select
            class="mb-3 mr-sm-3 mb-sm-0 custom-select"
            v-model="year"
            @change="serachAttendance(false)"
          >
            <option value="">--Select Year--</option>
            <option
              v-for="(year, index) in getYears"
              :key="index"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
        </div>
      </div>
      <div class="v-data-table v-data-table--fixed-header theme--light">
        <div class="v-data-table__wrapper">
          <table class="table table-hover">
            <thead>
              <tr style="background-color: #f2f4f5">
                <th width="120px">Day</th>

                <th>Worked Shift</th>
                <th>Total Hours</th>
                <th>Status</th>
              </tr>
              <tr v-if="loader">
                <td colspan="12" class="text-center">
                  <data-loader :type="'dark'" :padding="10"></data-loader>
                </td>
              </tr>
              <tr>
                <td
                  colspan="12"
                  class="text-center"
                  v-if="getAttendance.length < 1 && !loader"
                >
                  <div class="p-6">
                    <span>No Attendance(s) found</span>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody v-if="getAttendance.length > 0 && !loader">
              <tr
                v-for="(aItem, index) in getAttendance"
                :key="index"
                :style="'background-color:' + aItem.color_code + '14'"
              >
                <td>
                  <span style="font-size: 18px; color: grey">
                    {{ aItem.date }}</span
                  >
                  <p>
                    <span style="font-size: 12px">{{ aItem.dayName }}</span>
                  </p>
                </td>

                <!-- <td class="check-in-out-box" v-if="aItem.attendance!=null">
                  <span>{{ aItem.check_in }}</span> -
                  <span> {{ aItem.check_out }}</span>
                </td>
                 <td class="check-in-out-box"  v-else>
                  <span>--:--</span> -
                  <span> --:--</span>
                </td> -->

                <td>
             
                  <span>{{aItem.attendance.cin==''?'--:--':aItem.attendance.cin}}</span>
                  <i class="fa fa-arrow-right" style="font-size: 10px;color: #989595;margin-left: 3px;margin-right: 5px;"></i>
                  <span>{{aItem.attendance.cout}}</span>
                </td>

                <td>
                  <span style="font-weight:bold">0h 00m</span>
                </td>
                <td >
                  <span v-if="aItem.isDayOff" class="badge badge-secondary"
                    >Day Off</span
                  >
                  <span
                    v-else-if="aItem.holiday != null"
                    v-b-tooltip
                    :title="aItem.holiday.title"
                    :class="' badge badge-info holiday'"
                    >Holiday</span
                  >
                  <span v-else>
                    <span class="badge badge-danger" style="margin-bottom: 5px"
                      >Absent</span
                    >
                    <!-- <p>
                      <span class="text-danger" style="font-size:12px">Late In</span>
                      <i
                        class="fa fa-arrow-right"
                        style="
                          font-size: 10px;
                          color: #4e4c4c;
                          margin-left: 3px;
                          margin-right: 5px;
                        "
                      ></i>
                      <span class="text-danger" style="font-size:12px">Early Out</span>
                    </p> -->
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FETCH_ATTENDANCE } from "@/core/services/store/actions.type";
import dataLoader from "@/includes/content/DataLoader";
export default {
  data() {
    return {
      loader: false,
      year: "",
      month: "",
      date: "",
    };
  },
  components: {
    "data-loader": dataLoader,
  },
  computed: {
    ...mapGetters(["getAttendance","getMonths","getYears"]),
  },
  mounted() {
    this.serachAttendance();
  },
  filters: {
    holiday_leave(data) {
      return data.title;
    },
  },

  methods: {
    serachAttendance(besidedate = false) {
      const year = this.year;
      const month = this.month;
      if (!besidedate) {
        this.date = "";
      }
      const date = this.date;

      this.loader = true;
      this.$store
        .dispatch(FETCH_ATTENDANCE, { year, month, date })
        .then(() => {
          this.loader = false;
          this.year = this.getAttendance.cyear;
          this.month = parseInt(this.getAttendance.cmonth);
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
};
</script>
